<template>
  <div class="entrustChildUser">
    <v-list
      ref="list"
      :isAdd="true"
      :headers="headers"
      :tableUrl="tableUrl"
      :searchParam.sync="searchParam"
      :extraParams="extraParams"
    >
      <template #headBtnSlot>
        <v-button text="返回" @click="goBack"></v-button>
      </template>
      <template #searchSlot>
        <v-input label="昵称" v-model="searchParam.name" clearable></v-input>
        <v-input label="手机号" v-model="searchParam.name" clearable></v-input>
        <v-date-picker
          label="课程日期"
          v-model="createTime"
          type="datetimerange"
          formatValue="yyyy-MM-dd HH:mm"
          format="yyyy-MM-dd HH:mm"
          startPlaceholder="创建开始时间"
          endPlaceholder="创建结束时间"
        />
        <v-select
          clearable
          :options="timeDuringOps"
          v-model="searchParam.timeDuring"
          label="课程时间"
        />
      </template>
      <template #operateSlot="scope">
        <v-button text="详情" type="text" @click="toView(scope.row)" />
      </template>
    </v-list>
  </div>
</template>

<script>
import { getClassFeedbackForManageUrl } from "./api.js";
import {
  timeDuringMap,
  setTimeDuringOps,
  classStatusMap,
  setClassStatusOps,
} from "./map.js";
import {
  createImgVNode,
  createAlinkVNode,
  createHTMLVNode,
} from "@/utils/utils.js";

export default {
  name: "entrustChildUser",
  data() {
    return {
      searchParam: {
        scheduleId: "",
        name: "",
        phone: "",
        dayName: undefined,
        timeDuring: undefined,
      },
      timeDuringOps: setTimeDuringOps(),
      classStatusOps: setClassStatusOps(),
      tableUrl: getClassFeedbackForManageUrl,
      headers: [
        {
          prop: "dayName",
          label: "课程日期",
        },
        {
          prop: "timeDuring",
          label: "课程时间",
          formatter: (row, prop) => {
            return timeDuringMap[row[prop]];
          },
        },

        {
          prop: "parentsName",
          label: "昵称",
        },
        {
          prop: "parentsPhone",
          label: "手机号",
        },
        {
          prop: "grade",
          label: "活动评分",
        },
        {
          prop: "feedbacksSuggestion",
          label: "意见反馈",
        },
        // {
        //   prop: "feedbackPicture",
        //   label: "反馈图片",
        //   align: "center",
        //   formatter: (row, prop) =>
        //     createAlinkVNode(this, row, prop, {
        //       text: row[prop],
        //       cb: () => this.toUserList(row),
        //     }),
        // },
        {
          prop: "updateTime",
          label: "提交时间",
        },
      ],
    };
  },
  created() {
    this.searchParam.scheduleId = this.$route.query.scheduleId;
    this.$setBreadList("意见反馈");
  },
  computed: {
    createTime: {
      get() {
        return !this.searchParam.startTime && !this.searchParam.endTime
          ? []
          : [this.searchParam.startTime, this.searchParam.endTime];
      },
      set(val) {
        [this.searchParam.startTime, this.searchParam.endTime] = val || [
          "",
          "",
        ];
      },
    },
    extraParams() {
      return {
        tenantId: this.$store.state.app.userInfo.tenantId,
      };
    },
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
    toView(row) {
      this.$router.push({
        name: "entrustChildUserForm",
        query: {
          id: row.id,
        },
      });
    },
  },
};
</script>
<style lang="less" scoped>
.entrustChildUser {
  box-sizing: border-box;
  height: 100%;
}
</style>
